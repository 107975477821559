var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('a-modal',{attrs:{"centered":"","mask-closable":false,"ok-button-props":{ props: { loading: _vm.submitting } },"title":"新增账号"},on:{"ok":_vm.handleSubmit},model:{value:(_vm.isShow),callback:function ($$v) {_vm.isShow=$$v},expression:"isShow"}},[_c('a-form',{staticClass:"custom-compact-form",attrs:{"form":_vm.form,"label-col":{ span: 6 },"wrapper-col":{ span: 15 }},on:{"submit":_vm.handleSubmit}},[_c('a-form-item',{attrs:{"label":"姓名"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['emp_name', {
            normalize: this.$lodash.trim,
            rules: [
              { required: true, message: '请输入姓名' },
              { max: 50, message: '最多50个字符' } ]
          }]),expression:"['emp_name', {\n            normalize: this.$lodash.trim,\n            rules: [\n              { required: true, message: '请输入姓名' },\n              { max: 50, message: '最多50个字符' },\n            ]\n          }]"}],attrs:{"autocomplete":"off"}})],1),_c('a-form-item',{attrs:{"label":"联系电话"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['phone_number', {
            normalize: this.$lodash.trim,
            rules: [
              { required: true, message: '请输入手机号' },
              { pattern: /^1[3-9]\d{9}$/, message: '手机号格式不正确' } ]
          }]),expression:"['phone_number', {\n            normalize: this.$lodash.trim,\n            rules: [\n              { required: true, message: '请输入手机号' },\n              { pattern: /^1[3-9]\\d{9}$/, message: '手机号格式不正确' },\n            ]\n          }]"}],attrs:{"autocomplete":"off"}})],1),_c('a-form-item',{attrs:{"label":"登录工号"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['emp_no', {
            normalize: this.$lodash.trim,
            rules: [
              { required: true, message: '请输入登录工号' },
              { pattern: /^[a-zA-Z0-9_]{4,50}$/, message: '只能由4-50位 字母、数字 或 下划线 组成' } ]
          }]),expression:"['emp_no', {\n            normalize: this.$lodash.trim,\n            rules: [\n              { required: true, message: '请输入登录工号' },\n              { pattern: /^[a-zA-Z0-9_]{4,50}$/, message: '只能由4-50位 字母、数字 或 下划线 组成' },\n            ]\n          }]"}],attrs:{"autocomplete":"off"}})],1),_c('a-form-item',{attrs:{"label":"登录密码"}},[_c('a-input-password',{directives:[{name:"decorator",rawName:"v-decorator",value:(['password', {
            rules: [
              { required: true, message: '请输入登录密码' },
              { min: 6, message: '最少6个字符' },
              { max: 50, message: '最多50个字符' },
              { pattern: /^\S.*\S$/, message: '首尾不能为空格' }
            ]
          }]),expression:"['password', {\n            rules: [\n              { required: true, message: '请输入登录密码' },\n              { min: 6, message: '最少6个字符' },\n              { max: 50, message: '最多50个字符' },\n              { pattern: /^\\S.*\\S$/, message: '首尾不能为空格' }\n            ]\n          }]"}],attrs:{"autocomplete":"new-password"}})],1),_c('a-form-item',{attrs:{"label":"角色"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:(['role_id', {
            rules: [{ required: true, message: '请选择角色' }]
          }]),expression:"['role_id', {\n            rules: [{ required: true, message: '请选择角色' }]\n          }]"}],attrs:{"loading":_vm.roleOptions.length === 0}},_vm._l((_vm.roleOptions),function(role){return _c('a-select-option',{key:role.id,attrs:{"value":role.id,"disabled":!role.effective}},[_vm._v(" "+_vm._s(role.name)+" ")])}),1)],1),_c('a-form-item',{attrs:{"label":"照片"}},[_c('span',{staticClass:"tip-text"},[_vm._v("支持png/jpg格式照片，10M以内, 建议尺寸宽高比180:180px，最多1张 ")]),_c('upload-image',{directives:[{name:"decorator",rawName:"v-decorator",value:(['attachments',{
            rules:[
              {validator: _vm.checkAttachments}
            ]
          }]),expression:"['attachments',{\n            rules:[\n              {validator: checkAttachments}\n            ]\n          }]"}],attrs:{"is-add-watermark":_vm.isAddWatermark,"accept":".jpg,.jpeg,.png","max-count":1},on:{"change":_vm.handleUploadImage}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }